import React, { useEffect } from 'react'
import { useDeleteTrade, useTrades } from 'agency/queries/trades'
import Modal from 'agency/components/Modal'
import { Trade } from 'agency/types/trades'
import Select from 'components/Select'
import i18next from 'i18next'
import Text from 'components/Text'
import Divider from 'components/Divider'
import toast from 'react-hot-toast'

type DeleteTradeWithEmployeesContentProps = {
  targetTrade: Trade | undefined
  onTargetTradeChange: (tradeId: string) => void
  otherTradesChoices: Trade[]
}

const DeleteTradeWithEmployeesContent = ({
  targetTrade,
  onTargetTradeChange,
  otherTradesChoices,
}: DeleteTradeWithEmployeesContentProps) =>
  otherTradesChoices.length >= 2 ? (
    <>
      <Text className="mb-6">{i18next.t('agency.settings.trades.delete_trade_reassign')}</Text>
      <div className="flex flex-row gap-2 items-center mb-4">
        <Select
          value={targetTrade?.id}
          label={i18next.t('agency.settings.trades.target_trade')}
          onValueChange={onTargetTradeChange}
          options={otherTradesChoices.map((t: Trade) => ({ value: t.id, label: t.name }))}
        />
      </div>
    </>
  ) : (
    <Text className="mb-6">
      {i18next.t('agency.settings.trades.deleted_transfered_to', { tradeName: targetTrade?.name })}
    </Text>
  )

type DeleteTradeModalProps = {
  trade: Trade
  onClose: () => void
}

const DeleteTradeModal = ({ onClose, trade }: DeleteTradeModalProps) => {
  const [targetTrade, setTargetTrade] = React.useState<Trade | undefined>()
  const { mutate: deleteTrade } = useDeleteTrade({
    onSuccess: () => {
      toast.success(i18next.t('agency.settings.trades.delete_trade_success'))
      onClose()
    },
    onError: () => {
      toast.error(i18next.t('common.unknown_error'))
    },
  })
  const { data: trades } = useTrades()

  const otherTradesChoices = trades.filter((t) => t.id !== trade.id)

  useEffect(() => {
    if (!targetTrade && otherTradesChoices.length > 0) {
      setTargetTrade(otherTradesChoices[0])
    }
  }, [otherTradesChoices])

  const onSubmit = () => {
    if (trade.withEmployees && !targetTrade) {
      toast.error(i18next.t('agency.settings.trades.error_no_target_trade'))
      return
    }

    deleteTrade({ tradeId: trade.id, targetTradeId: targetTrade?.id })
  }

  const onTargetTradeChange = (tradeId: string) => {
    setTargetTrade(trades.find((t) => t.id === tradeId))
  }

  return (
    <Modal onRequestClose={onClose}>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          onSubmit()
        }}
      >
        <Text type="title" className="mb-2">
          {i18next.t('agency.settings.trades.delete_trade_title', { tradeName: trade.name })}
        </Text>
        <Divider className="mb-6" />

        {trade.withEmployees ? (
          <DeleteTradeWithEmployeesContent
            targetTrade={targetTrade}
            onTargetTradeChange={onTargetTradeChange}
            otherTradesChoices={otherTradesChoices}
          />
        ) : (
          <Text className="mb-6">{i18next.t('agency.settings.trades.delete_trade_warning')}</Text>
        )}

        <Modal.Actions submitText={i18next.t('agency.settings.trades.delete_trade')} actionUiStyle="delete" />
      </form>
    </Modal>
  )
}

export default DeleteTradeModal
