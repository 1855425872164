import { Agency } from 'agency/types/agency'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

type AgencyContextType = {
  agencies: Agency[]
  currentAgency?: Agency
  setAgencies: (agencies: Agency[]) => void
  setCurrentAgencyId: (agencyId: string) => void
  currentAgencyId: string | null
}

const AgencyContext = createContext<AgencyContextType>({
  currentAgency: undefined,
  agencies: [],
  setAgencies: () => {},
  setCurrentAgencyId: () => {},
  currentAgencyId: null,
})

const CURRENT_AGENCY_ID_KEY = 'currentAgencyId'

export const getLastAgencyId = () => localStorage.getItem(CURRENT_AGENCY_ID_KEY)

export const AgencyProvider = ({ children }: React.PropsWithChildren) => {
  const [agencies, setAgencies] = useState<Agency[]>([])
  const [currentAgencyId, setCurrentAgencyId] = useState<string | null>(getLastAgencyId())
  const { agencyId: currentAgencyIdFromUrl } = useParams()

  const handleSetCurrentAgencyId = useCallback(
    (agencyId: string) => {
      setCurrentAgencyId(agencyId)
      localStorage.setItem(CURRENT_AGENCY_ID_KEY, agencyId)
    },
    [setCurrentAgencyId]
  )

  useEffect(() => {
    if (currentAgencyIdFromUrl) {
      handleSetCurrentAgencyId(currentAgencyIdFromUrl)
    }
  }, [])

  const currentAgency = agencies.find(({ id }) => id === currentAgencyId)

  useEffect(() => {
    if (agencies.length === 0) return

    if (!currentAgency) {
      handleSetCurrentAgencyId(agencies[0].id)
    }
  }, [agencies])

  const value = useMemo(
    () => ({ agencies, currentAgency, currentAgencyId, setAgencies, setCurrentAgencyId: handleSetCurrentAgencyId }),
    [agencies, currentAgency, setAgencies, handleSetCurrentAgencyId, currentAgencyId]
  )

  return <AgencyContext.Provider value={value}>{children}</AgencyContext.Provider>
}

const useAgency = () => useContext(AgencyContext)

export default useAgency
