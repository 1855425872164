import React from 'react'
import { useEmployees } from 'agency/queries/employees'
import { useAgent, useAgents } from 'agency/queries/agents'
import { EmployeeInvitationStatus } from 'agency/types/employees'

import EmployeeListHeader from 'agency/components/agency_employees/AgencyEmployeeIndex/EmployeeListHeader'
import EmptyEmployeeList from 'agency/components/agency_employees/AgencyEmployeeIndex/EmptyEmployeeList'
import EmployeeListTable from 'agency/components/agency_employees/AgencyEmployeeIndex/EmployeeListTable'
import EmployeeInvitationModal from 'agency/components/EmployeeInvitationModal'
import { addErrorInfo, sendError } from 'utils/appsignal'
import { useTrades } from 'agency/queries/trades'
import useTabFromUrl from 'hooks/useTabFromUrl'
import Page from 'agency/components/Page'

const EmployeesList = () => {
  const { data: agent } = useAgent()

  const [openInvitationModal, setOpenInvitationModal] = React.useState(false)

  const defaultTab = useTabFromUrl<EmployeeInvitationStatus>({ choices: ['active', 'invited'], defaultValue: 'active' })
  const [currentTab, setCurrentTab] = React.useState<EmployeeInvitationStatus>(defaultTab)

  const { data: employees } = useEmployees()
  const { data: agents } = useAgents()
  const { data: trades } = useTrades()

  if (!agent || !employees) return null

  if (!Array.isArray(employees)) {
    addErrorInfo('employees', employees)
    sendError(new Error(`Employees is of type ${typeof employees}`))
    return null
  }

  const filteredEmployees = employees.filter((employee) => employee.status === currentTab)

  return (
    <Page>
      {openInvitationModal ? <EmployeeInvitationModal closeModal={() => setOpenInvitationModal(false)} /> : null}
      <EmployeeListHeader
        employees={employees}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        setOpenInvitationModal={setOpenInvitationModal}
      />

      {employees.length > 0 ? (
        <EmployeeListTable employees={filteredEmployees} agents={agents} trades={trades} currentTab={currentTab} />
      ) : (
        <EmptyEmployeeList setOpenInvitationModal={setOpenInvitationModal} />
      )}
    </Page>
  )
}

export default EmployeesList
