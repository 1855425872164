import React, { useLayoutEffect, useRef, useState } from 'react'
import i18next from 'i18next'
import classNames from 'classnames'
import Button from 'components/Button'
import Text from 'components/Text'
import { ImgComponentProps } from 'components/DocumentViewer'
import Icon from 'components/Icon'
import ClickableDiv from 'components/ClickableDiv'

const applyZoom = (width: number, zoom: number) => (width * zoom) / 100

const isSideRotated = (rotationAngle: number) => rotationAngle % 180 !== 0

const imageTransform = (rotationAngle: number, translateHeight: number) => {
  let translate = ''

  if (rotationAngle === 90 || rotationAngle === -270) translate = `translateX(${translateHeight}px)`
  if (rotationAngle === -90 || rotationAngle === 270) translate = `translateX(-${translateHeight}px)`

  return `rotate(${rotationAngle}deg) ${translate}`
}

const ImageZoom = ({ src, alt, className }: ImgComponentProps) => {
  const [zoom, setZoom] = useState(100)
  const [containerProperties, setContainerProperties] = useState({ width: 0, height: 0 })
  const [rotationAngle, setRotationAngle] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!containerRef.current) return

    const rect = containerRef.current.getBoundingClientRect()
    setContainerProperties({
      height: rect.height,
      width: rect.width,
    })
  }, [])

  const handleRotationAngleChange = (rotationAngleAngle: number) => () => {
    setRotationAngle((prevRotationAngle) => (prevRotationAngle + rotationAngleAngle) % 360)
  }

  const imageTransformStyle = imageTransform(
    rotationAngle,
    Math.abs(containerProperties.height - containerProperties.width)
  )

  return (
    <div className="h-full">
      <div className="flex flex-row items-center justify-center gap-x-3 p-2 text-white bg-gray-800">
        <Button uiStyle="transparent" name={i18next.t('common.rotate_left')} onClick={handleRotationAngleChange(-90)}>
          <Icon name="rotate_left" className="text-white" />
        </Button>
        <Button
          uiStyle="transparent"
          name={i18next.t('common.zoom_out')}
          onClick={() => setZoom((prevZoom) => prevZoom - 10)}
          disabled={zoom <= 50}
        >
          -
        </Button>
        <ClickableDiv onClick={() => setZoom(100)} label={i18next.t('common.reset_zoom')}>
          <Text center className="min-w-14">
            {zoom}%
          </Text>
        </ClickableDiv>
        <Button
          uiStyle="transparent"
          name={i18next.t('common.zoom_in')}
          onClick={() => setZoom((prevZoom) => prevZoom + 10)}
          disabled={zoom >= 250}
        >
          +
        </Button>
        <Button uiStyle="transparent" name={i18next.t('common.rotate_right')} onClick={handleRotationAngleChange(90)}>
          <Icon name="rotate_right" className="text-white" />
        </Button>
      </div>
      <div className={classNames('image-container overflow-scroll', className)} ref={containerRef}>
        <div
          style={{
            width: `${applyZoom(containerProperties.width, zoom)}px`,
            height: `${applyZoom(isSideRotated(rotationAngle) ? containerProperties.width : containerProperties.height, zoom)}px`,
          }}
        >
          <img src={src} alt={alt} style={{ transform: imageTransformStyle }} className="object-contain h-full" />
        </div>
      </div>
    </div>
  )
}
export default ImageZoom
