import React, { useEffect, useRef } from 'react'
import i18next from 'i18next'
import Fuse from 'fuse.js'

import { DocumentGroupConfig } from 'agency/types/document_groups'
import Tag from 'components/Tag'
import Icon from 'components/Icon'
import { removeAccents } from 'utils/string'

const DOCUMENT_GROUP_CONFIGS_TO_SUGGEST = 10
const DOCUMENT_GROUP_CONFIG_SEARCH = new Fuse(window.document_group_configs, {
  keys: ['label'],
  getFn: (documentConfig: DocumentGroupConfig) => removeAccents(documentConfig.label),
})

type DocumentGroupConfigsSelectProps = {
  selectedDocumentConfigs: DocumentGroupConfig[]
  setSelectedDocumentConfigs: React.Dispatch<React.SetStateAction<DocumentGroupConfig[]>>
  removableDefault?: boolean // To remove once the default parameter as been changed
}

const DocumentGroupConfigsSelect = ({
  selectedDocumentConfigs,
  setSelectedDocumentConfigs,
  removableDefault,
}: DocumentGroupConfigsSelectProps) => {
  const [search, setSearch] = React.useState('')
  const [suggestedDocumentConfigs, setSuggestedDocumentConfigs] = React.useState<DocumentGroupConfig[]>([])
  const searchInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const selectedDocumentConfigSlugs = selectedDocumentConfigs.map((documentConfig) => documentConfig.slug)
    if (search) {
      setSuggestedDocumentConfigs(
        DOCUMENT_GROUP_CONFIG_SEARCH.search(search)
          .map((result) => result.item)
          .filter((documentKind) => !selectedDocumentConfigs.includes(documentKind))
      )
    } else {
      setSuggestedDocumentConfigs(
        window.document_group_configs
          .filter((documentConfig) => !selectedDocumentConfigSlugs.includes(documentConfig.slug))
          .slice(0, DOCUMENT_GROUP_CONFIGS_TO_SUGGEST)
      )
    }
  }, [search, selectedDocumentConfigs])

  return (
    <div>
      <div className="border border-medium rounded-lg shadow flex flex-col px-3 py-2 mb-4">
        <div className="flex gap-2 mb-4 flex-wrap">
          {selectedDocumentConfigs.map((documentConfig) => {
            const removable = removableDefault ?? !documentConfig.default
            return (
              <Tag
                key={documentConfig.slug}
                uiStyle="info"
                className="flex items-center"
                onClick={
                  removable
                    ? () => {
                        setSelectedDocumentConfigs((currentDocumentConfigs) =>
                          currentDocumentConfigs.filter(
                            (currentDocumentConfig) => currentDocumentConfig.slug !== documentConfig.slug
                          )
                        )
                        searchInputRef.current?.focus()
                      }
                    : undefined
                }
                name={
                  removable
                    ? i18next.t('agency.home.employee_invitation.remove_document', { label: documentConfig.label })
                    : undefined
                }
              >
                {documentConfig.label}
                {removable ? <Icon name="close" uiStyle="info" /> : null}
              </Tag>
            )
          })}
        </div>
        <input
          ref={searchInputRef}
          name={i18next.t('agency.home.employee_invitation.search_document_kind')}
          className="outline-none text-textSecondary"
          placeholder={i18next.t('agency.home.employee_invitation.search_document_kind')}
          type="text"
          style={{ outline: 'none' }}
          value={search}
          onChange={(event) => {
            setSearch(event.target.value)
          }}
        />
      </div>
      <div className="flex flex-wrap gap-2">
        {suggestedDocumentConfigs.map((suggestDocumentKind) => (
          <Tag
            key={suggestDocumentKind.slug}
            uiStyle="neutral"
            onClick={() => {
              setSelectedDocumentConfigs((currentDocumentConfigs) => [...currentDocumentConfigs, suggestDocumentKind])
              setSearch('')
              searchInputRef.current?.focus()
            }}
            name={`Add ${suggestDocumentKind.label}`}
          >
            + {suggestDocumentKind.label}
          </Tag>
        ))}
      </div>
    </div>
  )
}

export default DocumentGroupConfigsSelect
