import React from 'react'
import i18next from 'i18next'
import Text from 'components/Text'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { EmployeeIndex, EmployeeInvitationStatus } from 'agency/types/employees'
import Tab from 'agency/components/Tab'

export const countEmployeeForStatus = (employees: EmployeeIndex[], status: EmployeeInvitationStatus) =>
  employees.filter((employee) => employee.status === status).length

type EmployeeListHeaderProps = {
  employees: EmployeeIndex[]
  currentTab: EmployeeInvitationStatus
  setCurrentTab: (tab: EmployeeInvitationStatus) => void
  setOpenInvitationModal: (isOpen: boolean) => void
}

const EmployeeListHeader = ({
  employees,
  currentTab,
  setCurrentTab,
  setOpenInvitationModal,
}: EmployeeListHeaderProps) => (
  <div className="bg-white pt-6 px-14 border-b border-borderMedium">
    <div className="flex justify-between mb-4">
      <div>
        <Text type="title">{i18next.t('agency.home.employee_list_title')}</Text>
        <Text type="subtext">{i18next.t('agency.home.employee_list_description')}</Text>
      </div>
      <div>
        <Button
          type="button"
          onClick={() => {
            setOpenInvitationModal(true)
          }}
          name="Add employee"
        >
          <div className="flex items-center gap-1.5">
            <Icon name="add" uiStyle="light" />
            {i18next.t('agency.home.invite_employee')}
          </div>
        </Button>
      </div>
    </div>
    <div className="flex gap-8">
      <Tab
        label={i18next.t('agency.home.active_employees')}
        count={countEmployeeForStatus(employees, 'active')}
        isActive={currentTab === 'active'}
        tab="active"
        onClick={() => setCurrentTab('active')}
      />
      <Tab
        label={i18next.t('agency.home.pending_invites')}
        count={countEmployeeForStatus(employees, 'invited')}
        isActive={currentTab === 'invited'}
        tab="invited"
        onClick={() => setCurrentTab('invited')}
      />
    </div>
  </div>
)

export default EmployeeListHeader
