import React from 'react'
import i18next from 'i18next'

import { EmployeeIndex } from 'agency/types/employees'
import useAgency from 'agency/contexts/AgencyContext'
import TableList from 'components/TableList'

type EmployeeRowProps = {
  employee: EmployeeIndex
}

const EmployeeRow = ({ employee, children }: React.PropsWithChildren<EmployeeRowProps>) => {
  const { currentAgencyId } = useAgency()

  switch (employee.status) {
    case 'active':
      return (
        <TableList.Row
          key={employee.agency_employee_id}
          className="cursor-pointer"
          linkTo={`/agencies/${currentAgencyId}/agency_employees/${employee.agency_employee_id}`}
          ariaLabel={i18next.t('agency.home.employee_list.view_aria_link', {
            name: `${employee.last_name} ${employee.first_name}`,
          })}
        >
          {children}
        </TableList.Row>
      )
    case 'invited':
      return <TableList.Row key={employee.agency_employee_id}>{children}</TableList.Row>
  }
}

export default EmployeeRow
