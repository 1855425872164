import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import httpClient from 'utils/http-client'
import { Employee, EmployeeShow, EmployeeIndex } from 'agency/types/employees'
import useAgency from 'agency/contexts/AgencyContext'
import { DocumentGroup } from 'agency/types/document_groups'
import { TradeResponse } from './trades'

export const EMPLOYEES_KEY = ['employees']

export const getEmployeesKey = (agencyId: string | null) => [...EMPLOYEES_KEY, 'agency', agencyId]
export const getEmployeeKey = (agencyEmployeeId?: string) => [...EMPLOYEES_KEY, 'agencyEmployees', agencyEmployeeId]

type EmployeeResponse = Employee & {
  document_groups: DocumentGroup[]
  trade: TradeResponse
  social_security_number?: string
  birthdate?: string
  iban?: string
  bic?: string
}

const formatEmployee = (employee: EmployeeResponse): EmployeeShow => ({
  first_name: employee.first_name,
  last_name: employee.last_name,
  agency_employee_id: employee.agency_employee_id,
  phone_number: employee.phone_number,
  email: employee.email,
  documentGroups: employee.document_groups,
  socialSecuryNumber: employee.social_security_number,
  birthdate: employee.birthdate,
  iban: employee.iban,
  bic: employee.bic,
  trade: {
    id: employee.trade.id,
    name: employee.trade.name,
  },
})

export const useEmployee = (agencyEmployeeId?: string) => {
  const { currentAgencyId } = useAgency()

  const placeholderData = {
    agency_employee_id: agencyEmployeeId ?? '',
    first_name: '',
    last_name: '',
    email: '',
    documentGroups: [],
    trade: {
      id: '',
      name: '',
    },
  }

  const { data, ...rest } = useQuery<EmployeeShow>({
    queryKey: getEmployeeKey(agencyEmployeeId),
    queryFn: () =>
      httpClient<EmployeeResponse>(`/agencies/${currentAgencyId}/agency_employees/${agencyEmployeeId}`).then(
        formatEmployee
      ),
    placeholderData,
    retry: 0,
    enabled: !!(agencyEmployeeId && currentAgencyId),
    staleTime: 5 * 60 * 1000, // 5 minutes
  })

  return { data: data ?? placeholderData, ...rest }
}

export const useInvalidateEmployeeQuery = () => {
  const queryClient = useQueryClient()
  return (agencyEmployeeId: string) => queryClient.invalidateQueries({ queryKey: getEmployeeKey(agencyEmployeeId) })
}

export const useEmployees = () => {
  const { currentAgencyId } = useAgency()

  const { data, ...rest } = useQuery<EmployeeIndex[]>({
    queryKey: getEmployeesKey(currentAgencyId),
    queryFn: () => httpClient<EmployeeIndex[]>(`/agencies/${currentAgencyId}/agency_employees`),
    placeholderData: [],
    enabled: !!currentAgencyId,
    staleTime: 5 * 60 * 1000, // 5 minutes
  })

  return { data: data ?? [], ...rest }
}

type AddEmployeePayload = { email: string; firstName: string; lastName: string; phoneNumber?: string }

type UseAddEmployeeArgs = {
  onSuccess: (employee: EmployeeIndex) => void
  onError?: (error: Error) => void
}

export const useAddEmployee = ({ onSuccess, onError }: UseAddEmployeeArgs) => {
  const queryClient = useQueryClient()
  const { currentAgencyId } = useAgency()
  return useMutation({
    mutationFn: ({ email, firstName, lastName, phoneNumber }: AddEmployeePayload) =>
      httpClient<EmployeeIndex>(`/agencies/${currentAgencyId}/agency_employees`, {
        method: 'post',
        body: {
          email,
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber?.replaceAll(' ', '') ?? '',
        },
      }),
    onSuccess: (employee: EmployeeIndex) => {
      queryClient.setQueryData(getEmployeesKey(currentAgencyId), (employees: EmployeeIndex[]) => [
        ...employees,
        employee,
      ])
      onSuccess(employee)
    },
    onError,
  })
}

export const useRemoveEmployee = () => {
  const queryClient = useQueryClient()
  const { currentAgencyId } = useAgency()

  return useMutation({
    mutationFn: (agencyEmployeeId: string) =>
      httpClient<void>(`/agencies/${currentAgencyId}/agency_employees/${agencyEmployeeId}`, {
        method: 'delete',
      }),
    onSuccess: (_data: void, agencyEmployeeId: string) => {
      queryClient.setQueryData(getEmployeesKey(currentAgencyId), (employees: EmployeeIndex[]) =>
        employees.filter((e) => e.agency_employee_id !== agencyEmployeeId)
      )
    },
  })
}

type UpdateEmployeeProps = {
  onSuccess: () => void
  onError: (error: Error) => void
  agencyEmployeeId: string
}

type UpdateParams = {
  phone_number?: string
  trade_id?: string
}

type UpdateEmployeeResponse = Pick<EmployeeResponse, 'trade' | 'phone_number'>

export const useUpdateEmployee = ({ onSuccess, onError, agencyEmployeeId }: UpdateEmployeeProps) => {
  const queryClient = useQueryClient()
  const { currentAgencyId } = useAgency()

  return useMutation({
    mutationFn: ({ phone_number, trade_id }: UpdateParams) =>
      httpClient<UpdateEmployeeResponse>(`/agencies/${currentAgencyId}/agency_employees/${agencyEmployeeId}`, {
        method: 'put',
        body: {
          phone_number,
          trade_id,
        },
      }),
    onSuccess: (employee) => {
      queryClient.setQueryData(getEmployeeKey(agencyEmployeeId), (e: EmployeeShow) => ({
        ...e,
        trade: employee.trade,
        phone_number: employee.phone_number,
      }))
      onSuccess()
    },
    onError,
  })
}
