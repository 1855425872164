import React from 'react'
import dayjs from 'dayjs'
import classNames from 'classnames'
import TableList from 'components/TableList'
import { UploadedDocument } from 'types/documents'
import Text from 'components/Text'
import i18next from 'i18next'
import Tag from 'components/Tag'
import VerificationIcon from './VerificationIcon'
import { DisplayButton } from './ActionButton'

type DocumentRowProps = {
  document: UploadedDocument
  onDisplayClick: (documentId: string) => void
  expanded: boolean
}

const isExpired = (date?: string) => {
  if (!date) return false
  return dayjs(date).isBefore(dayjs())
}

const getTag = (document: UploadedDocument) => {
  if (document.verification_status === 'fraudulent') {
    return (
      <Tag uiStyle="error" withDot>
        {i18next.t('document_groups.status.fraudulent')}
      </Tag>
    )
  }
  if (
    [
      'non_compliant',
      'unreadable',
      'missing_name',
      'missing_double_sided',
      'missing_signature',
      'missing_date',
    ].includes(document.verification_status)
  ) {
    return (
      <Tag uiStyle="error" withDot>
        {i18next.t(`document_groups.status.${document.verification_status}`)}
      </Tag>
    )
  }
  if (isExpired(document.expiration_date)) {
    return (
      <Tag uiStyle="error" withDot>
        {i18next.t('document_groups.status.expired')}
      </Tag>
    )
  }

  return (
    <Tag uiStyle="success" withDot>
      {i18next.t('documents.status.added')}
    </Tag>
  )
}

const DocumentRow = ({ document, onDisplayClick, expanded }: DocumentRowProps) => (
  <TableList.Row
    key={document.id}
    className={classNames('px-6 [&>*:first-child]:w-5/12', { 'bg-backgroundPrimary': expanded })}
    ariaLabel={document.label}
  >
    <div className="flex flex-row items-center">
      <Text uiStyle="bold" type="text">
        {document.label}
      </Text>
      <VerificationIcon status={document.verification_status} slug={document.kind_slug} />
    </div>
    {getTag(document)}
    <Text type="text">{document.expiration_date ? dayjs(document.expiration_date).format('DD/MM/YYYY') : '-'}</Text>
    <div />
    <DisplayButton
      label={i18next.t('agency.employee_profile.document_section.document_list.expand_for', {
        label: document.label,
      })}
      onClick={() => {
        onDisplayClick(document.id)
      }}
    />
  </TableList.Row>
)

export const MissingDocumentRow = ({ kind_slug, expanded }: { kind_slug: string; expanded: boolean }) => (
  <TableList.Row
    key={kind_slug}
    className={classNames('px-6', { 'bg-backgroundPrimary': expanded })}
    ariaLabel={i18next.t(`document_group_config.${kind_slug}`)}
  >
    <div className="flex flex-row items-center">
      <Text uiStyle="bold" type="text">
        {i18next.t(`document_group_config.${kind_slug}`)}
      </Text>
    </div>
    <Tag uiStyle="error" withDot>
      {i18next.t('document_groups.status.missing')}
    </Tag>
    <Text type="text">-</Text>
    <div />
    <div />
  </TableList.Row>
)

export default DocumentRow
