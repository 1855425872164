import React, { useEffect } from 'react'
import i18next from 'i18next'
import PasswordInput from 'components/PasswordInput'
import { isAbortError } from 'utils/errors'
import { checkPasswordStrength } from 'utils/password'
import useDebouncedCallback from 'hooks/useDebouncedCallback'
import skribe_constants from 'utils/constants'

const MAXIMUM_PASSWORD_LENGTH = 50

type PasswordCreationInputProps = {
  className?: string
  label: string
  value: string
  onChange: (value: string) => void
  errorMessage: string | undefined
  setErrorMessage: (errorMessage: string | undefined) => void
  setLoading: (loading: boolean) => void
}

const PasswordCreationInput = ({
  className,
  label,
  value,
  onChange,
  errorMessage,
  setErrorMessage,
  setLoading,
}: PasswordCreationInputProps) => {
  const debouncedVerifyPassword = useDebouncedCallback((currentPassword: string, signal: AbortSignal) => {
    checkPasswordStrength(currentPassword, signal)
      .then((passwordStrength) => {
        if (passwordStrength.isStrongEnough) {
          setErrorMessage(undefined)
        } else {
          setErrorMessage(i18next.t(`common.password_errors.${passwordStrength.reason}`))
        }
      })
      .catch((error) => {
        if (!isAbortError(error)) {
          throw error
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, 300)

  useEffect(() => {
    if (skribe_constants.DEMO_MODE) return
    const controller = new AbortController()
    setLoading(true)
    setErrorMessage(undefined)
    debouncedVerifyPassword(value, controller.signal)
    return () => {
      controller.abort()
    }
  }, [value])

  return (
    <PasswordInput
      className={className}
      value={value}
      maxLength={MAXIMUM_PASSWORD_LENGTH}
      label={label}
      onChange={(newValue) => onChange(newValue)}
      error={errorMessage}
    />
  )
}

export default PasswordCreationInput
