import React, { PropsWithChildren, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useAgent } from 'agency/queries/agents'
import SkribeLoadingPage from './SkribeLoadingPage'

const LoggedInRoutes = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const { data: agent, error, isLoading } = useAgent()

  useEffect(() => {
    if (error) {
      navigate('/login')
    }
  }, [error])

  if (!agent || isLoading) return <SkribeLoadingPage />

  return children ?? <Outlet />
}

export default LoggedInRoutes
