import React, { useEffect, useState } from 'react'
import i18next from 'i18next'

import { EmployeeIndex, EmployeeInvitationStatus } from 'agency/types/employees'
import { Agent } from 'agency/types/agents'
import { Trade } from 'agency/types/trades'
import useAgency from 'agency/contexts/AgencyContext'
import TableList from 'components/TableList'
import Text from 'components/Text'
import Input from 'components/Input'
import Select from 'components/Select'
import Background from 'agency/components/Background'
import { Sort, SortKey, filterAndSortEmployees } from 'agency/utils/employee-filters'
import ActiveEmployeeRow from './EmployeeList/ActiveEmployeeRow'
import InvitedEmployeeRow from './EmployeeList/InvitedEmployeeRow'
import { ActiveHeader, InvitedHeader } from './EmployeeList/Headers'

type EmployeeListTableProps = {
  employees: EmployeeIndex[]
  agents: Agent[]
  trades: Trade[]
  currentTab: EmployeeInvitationStatus
}

const EmployeeListTable = ({ employees, agents, trades, currentTab }: EmployeeListTableProps) => {
  const { currentAgencyId } = useAgency()
  const [sort, setSort] = useState<Sort>({ key: 'invited_at', direction: 'desc' })
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedAgentId, setSelectedAgentId] = useState<string>()
  const [selectedTradeId, setSelectedTradeId] = useState<string>()

  const filteredEmployees = filterAndSortEmployees(employees, sort, {
    searchQuery,
    agentId: selectedAgentId,
    tradeId: selectedTradeId,
  })

  const onSortChange = (sortKey: SortKey) => {
    setSort((prevSort) => ({
      key: sortKey,
      direction: prevSort.key === sortKey ? (prevSort.direction === 'asc' ? 'desc' : 'asc') : 'asc',
    }))
  }

  const RowComponent = currentTab === 'active' ? ActiveEmployeeRow : InvitedEmployeeRow
  const HeaderComponent = currentTab === 'active' ? ActiveHeader : InvitedHeader

  useEffect(() => {
    setSelectedAgentId(undefined)
  }, [currentAgencyId])

  const showTrade = trades.length >= 2 && (!selectedTradeId || selectedTradeId === 'all')

  return (
    <Background>
      <div className="flex flex-row items-center mb-2 gap-2">
        <Input
          onChange={setSearchQuery}
          name="search_query"
          placeholder={i18next.t('agency.home.employee_list.filters.search_employee')}
          type="text"
          className="min-w-64"
        />
        {agents.length > 0 && (
          <Select
            key={currentAgencyId}
            value={selectedAgentId}
            onValueChange={setSelectedAgentId}
            placeholder={i18next.t('agency.home.employee_list.filters.filter_by_agent')}
            options={[
              ...agents.map((agent: Agent) => ({ value: agent.id, label: `${agent.firstName} ${agent.lastName}` })),
              { value: 'all', label: i18next.t('agency.home.employee_list.filters.all_agents') },
            ]}
          />
        )}
        {trades.length >= 2 && (
          <Select
            key={`trade-selector-${currentAgencyId}`}
            value={selectedTradeId}
            onValueChange={setSelectedTradeId}
            placeholder={i18next.t('agency.home.employee_list.filters.filter_by_trade')}
            options={[
              ...trades.map((trade) => ({ value: trade.id, label: trade.name })),
              { value: 'all', label: i18next.t('agency.home.employee_list.filters.all_trades') },
            ]}
          />
        )}
      </div>
      <TableList>
        <HeaderComponent sort={sort} onSortChange={onSortChange} showTrade={showTrade} />
        {filteredEmployees.map((employee) => (
          <RowComponent key={employee.agency_employee_id} employee={employee} showTrade={showTrade} />
        ))}

        {filteredEmployees.length === 0 && (
          <TableList.Row>
            <Text>{i18next.t('agency.home.employee_list.filters.empty')}</Text>
          </TableList.Row>
        )}
      </TableList>
    </Background>
  )
}

export default EmployeeListTable
