import Page from 'agency/components/Page'
import Tab from 'agency/components/Tab'
import { EmployeeShow } from 'agency/types/employees'
import Text from 'components/Text'
import i18next from 'i18next'
import React from 'react'
import { capitalizeFirstLetter } from 'utils/string'

const EMPLOYEE_TABS = ['documents', 'informations'] as const
export type EmployeeTabs = (typeof EMPLOYEE_TABS)[number]

type EmployeeProfileHeaderProps = {
  employee: EmployeeShow
  currentTab: EmployeeTabs
  onTabChange: (tab: EmployeeTabs) => void
}

const EmployeeProfileHeader = ({ employee, currentTab, onTabChange }: EmployeeProfileHeaderProps) => {
  let employeeInformations = employee.email

  if (employee.phone_number) {
    employeeInformations += ` - ${employee.phone_number}`
  }

  return (
    <Page.Header
      id="employee_profile_header"
      tabs={[
        <Tab
          key="employee-documents"
          label={i18next.t('agency.employee_profile.document_section.title')}
          isActive={currentTab === 'documents'}
          tab="documents"
          count={employee.documentGroups.length}
          onClick={() => onTabChange('documents')}
        />,
        <Tab
          key="employees-informations"
          label={i18next.t('agency.employee_profile.personal_details_section.title')}
          isActive={currentTab === 'informations'}
          tab="informations"
          onClick={() => onTabChange('informations')}
        />,
      ]}
    >
      <Text type="title" className="grow">
        {employee.last_name.toUpperCase()} {capitalizeFirstLetter(employee.first_name)}
      </Text>
      <Text type="subtext">{employeeInformations}</Text>
    </Page.Header>
  )
}

export default EmployeeProfileHeader
