import React from 'react'
import i18next from 'i18next'
import SkribeLogo from 'components/SkribeLogo'
import Button from './Button'
import Text from './Text'

const ErrorPlaceholder = () => {
  const reloadPage = () => {
    window.location.reload()
  }

  return (
    <div className="flex flex-col justify-center items-center h-full w-full">
      <SkribeLogo className="mb-5" />
      <Text type="subtitle" className="mb-5">
        {i18next.t('common.errors.title')}
      </Text>
      <Text className="mb-5 text-center">{i18next.t('common.errors.message')}</Text>
      <Button name="reload" type="button" onClick={reloadPage} className="mb-5">
        {i18next.t('common.errors.reload')}
      </Button>
    </div>
  )
}

export default ErrorPlaceholder
