import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import i18next from 'i18next'
import { useEmployee } from 'agency/queries/employees'
import useAgency from 'agency/contexts/AgencyContext'
import { DocumentGroup } from 'agency/types/document_groups'
import EmployeeProfileHeader, {
  EmployeeTabs,
} from 'agency/components/agency_employees/AgencyEmployeeShow/EmployeeProfileHeader'
import DocumentGroupList from 'agency/components/agency_employees/AgencyEmployeeShow/DocumentGroupList'
import DocumentPreview from 'agency/components/agency_employees/AgencyEmployeeShow/DocumentPreview'
import DocumentUpload from 'agency/components/agency_employees/AgencyEmployeeShow/DocumentUpload'
import Background from 'agency/components/Background'
import { getAgencyRoute } from 'agency/utils/agency-routes'
import EmployeeInformation from 'agency/components/agency_employees/AgencyEmployeeShow/EmployeeInformation'
import useTabFromUrl from 'hooks/useTabFromUrl'
import Page from 'agency/components/Page'

const EmployeeProfile = () => {
  const { currentAgencyId } = useAgency()
  const { agencyEmployeeId } = useParams()
  const navigate = useNavigate()
  const [displayDocumentPreviewForDocumentId, setDisplayDocumentPreviewForDocumentId] = useState<string>('')

  const defaultTab = useTabFromUrl<EmployeeTabs>({ choices: ['documents', 'informations'], defaultValue: 'documents' })
  const [currentTab, setCurrentTab] = useState<EmployeeTabs>(defaultTab)
  const [documentGroupsUploadChoices, setDocumentGroupsUploadChoices] = useState<DocumentGroup[]>([])

  const { data: employee, error } = useEmployee(agencyEmployeeId)

  useEffect(() => {
    if (!error) return

    toast.error(i18next.t('agency.employee_profile.not_found'))
    if (window.user_id_at_spa_load) navigate(getAgencyRoute(currentAgencyId, '/agency_employees'))
  }, [error])

  if (!employee || !agencyEmployeeId) return null

  return (
    <Page>
      {displayDocumentPreviewForDocumentId ? (
        <DocumentPreview
          documentId={displayDocumentPreviewForDocumentId}
          agencyEmployeeId={agencyEmployeeId}
          closePreview={() => setDisplayDocumentPreviewForDocumentId('')}
        />
      ) : null}

      {documentGroupsUploadChoices.length > 0 ? (
        <DocumentUpload
          documentGroups={documentGroupsUploadChoices}
          agencyEmployeeId={agencyEmployeeId}
          closePreview={() => setDocumentGroupsUploadChoices([])}
        />
      ) : null}
      <EmployeeProfileHeader employee={employee} currentTab={currentTab} onTabChange={(tab) => setCurrentTab(tab)} />
      <Background>
        {currentTab === 'documents' ? (
          <DocumentGroupList
            documentGroups={employee.documentGroups}
            agencyEmployeeId={agencyEmployeeId}
            setDisplayDocumentPreviewForDocumentId={setDisplayDocumentPreviewForDocumentId}
            setDocumentGroupsUploadChoices={setDocumentGroupsUploadChoices}
          />
        ) : (
          <EmployeeInformation employee={employee} />
        )}
      </Background>
    </Page>
  )
}

export default EmployeeProfile
