import React from 'react'
import i18next from 'i18next'
import toast from 'react-hot-toast'

import { DocumentGroupConfig } from 'agency/types/document_groups'
import DocumentGroupConfigsSelect from 'agency/components/DocumentGroupConfigsSelect'
import Divider from 'components/Divider'
import Text from 'components/Text'
import Input from 'components/Input'
import Modal from 'agency/components/Modal'
import { Trade } from 'agency/types/trades'

export type SubmitTradePayload = Pick<Trade, 'name' | 'documentGroupsSlugs'>

type TradeRequestModalProps = {
  closeModal: () => void
  trade?: Trade
  onSubmit: (trade: SubmitTradePayload) => void
}

const getDefaultSelectedDocumentConfigs = (trade: Trade | undefined): DocumentGroupConfig[] => {
  if (!trade) return []

  return trade.documentGroupsConfigs
}

const TradeModal = ({ closeModal, onSubmit, trade }: TradeRequestModalProps) => {
  const [tradeName, setTradeName] = React.useState(trade?.name ?? '')
  const [selectedDocumentConfigs, setSelectedDocumentConfigs] = React.useState<DocumentGroupConfig[]>(
    getDefaultSelectedDocumentConfigs(trade)
  )

  const [errors, setErrors] = React.useState({ tradeName: '' })

  const handleOnSubmit = () => {
    const documentGroupsSlugs = selectedDocumentConfigs.map((documentKind) => documentKind.slug)

    if (documentGroupsSlugs.length === 0) {
      toast.error(i18next.t('agency.settings.trades.error_no_document_group'))
      return
    }

    if (!tradeName) {
      setErrors({ ...errors, tradeName: i18next.t('agency.settings.trades.error_no_name') })
      toast.error(i18next.t('agency.settings.trades.error_no_name'))
      return
    }

    onSubmit({
      name: tradeName,
      documentGroupsSlugs,
    })
  }

  const title = trade?.id
    ? i18next.t('agency.settings.trades.update_title', { tradeName: trade.name })
    : i18next.t('agency.settings.trades.add')

  const submitText = trade?.id ? i18next.t('agency.settings.trades.update') : i18next.t('agency.settings.trades.add')

  return (
    <Modal className="overflow-y-scroll" onRequestClose={closeModal}>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          handleOnSubmit()
        }}
      >
        <div className="overflow-y-auto">
          <Text type="title" className="mb-2">
            {title}
          </Text>
          <Divider className="mb-6" />
          <Input
            className="mb-6"
            label={i18next.t('agency.settings.trades.name_label')}
            name={i18next.t('agency.settings.trades.name_label')}
            onChange={(newName) => {
              setTradeName(newName)
              setErrors({ tradeName: '' })
            }}
            placeholder={i18next.t('agency.settings.trades.name_placeholder')}
            error={errors.tradeName}
            value={tradeName}
          />

          {trade && <Text className="mb-1.5">{i18next.t('agency.settings.trades.update_futur_employees')}</Text>}

          <Text type="text" uiStyle="bold" className="mb-1.5">
            {i18next.t('agency.settings.trades.document_groups_slugs')}
          </Text>
          <DocumentGroupConfigsSelect
            selectedDocumentConfigs={selectedDocumentConfigs}
            setSelectedDocumentConfigs={setSelectedDocumentConfigs}
            removableDefault
          />
        </div>
        <Modal.Actions submitText={submitText} />
      </form>
    </Modal>
  )
}

export default TradeModal
