import React from 'react'
import { DocumentVerificationStatus } from 'types/documents'
import Tooltip from 'components/Tooltip'
import Icon, { IconName, IconUiStyle } from 'components/Icon'
import i18next from 'i18next'
import constants from 'utils/constants'
import classNames from 'classnames'

const DISPLAY_VERIFICATION_ICON_TYPES = ['compliant', 'fraudulent', 'required_auto', 'required_manual']

type VerificationStatusWithToolTip = Extract<
  DocumentVerificationStatus,
  'compliant' | 'fraudulent' | 'required_auto' | 'required_manual'
>

type TooltipConfiguration = {
  icon: IconName
  uiStyle: IconUiStyle
  content_key: string
}

const TooltipConfigurationByVerifactionStatus: Record<VerificationStatusWithToolTip, TooltipConfiguration> = {
  compliant: {
    icon: 'verified',
    uiStyle: 'success',
    content_key: 'document_groups.tooltip.compliant',
  },
  fraudulent: {
    icon: 'release_alert',
    uiStyle: 'error',
    content_key: 'document_groups.tooltip.fraudulent',
  },
  required_auto: {
    icon: 'info',
    uiStyle: 'warning',
    content_key: 'document_groups.tooltip.required',
  },
  required_manual: {
    icon: 'info',
    uiStyle: 'warning',
    content_key: 'document_groups.tooltip.required',
  },
}

const requiredButNotVerifiableByCtms = (status: DocumentVerificationStatus, slug: string) =>
  ['required_auto', 'required_manual'].includes(status) && !constants.VERIFIABLE_BY_CTMS_SLUGS.includes(slug)

type VerificationIconProps = { status: DocumentVerificationStatus; slug: string }

const VerificationIcon = ({ status, slug }: VerificationIconProps) => {
  if (!DISPLAY_VERIFICATION_ICON_TYPES.includes(status)) return

  if (requiredButNotVerifiableByCtms(status, slug)) return null

  const tooltipConfig = TooltipConfigurationByVerifactionStatus[status as VerificationStatusWithToolTip]

  return (
    <Tooltip
      trigger={
        <Icon
          name={tooltipConfig.icon}
          className={classNames('ml-2', {
            'verification-icon-compliant': status === 'compliant',
            'verification-icon-required': status === 'required_auto' || status === 'required_manual',
            'verification-icon-fraudulent': status === 'fraudulent',
          })}
          uiStyle={tooltipConfig.uiStyle}
        />
      }
      content={i18next.t(tooltipConfig.content_key)}
    />
  )
}

export default VerificationIcon
