import { useLocation } from 'react-router-dom'

const useTabFromUrl = <T>({ choices, defaultValue }: { choices: T[]; defaultValue: T }) => {
  const { hash } = useLocation()

  const isTabChoice = (value: string) => choices.includes(value as T)

  const tabFromUrl = hash.replace('#', '')
  return (isTabChoice(tabFromUrl) ? tabFromUrl : defaultValue) as T
}

export default useTabFromUrl
