import React from 'react'

import { Employee } from 'agency/types/employees'
import Invite from 'agency/components/EmployeeInvitationModal/Invite'
import Documents from 'agency/components/EmployeeInvitationModal/Documents'
import Modal from 'agency/components/Modal'
import classNames from 'classnames'

type EmployeeInvitationModalProps = {
  closeModal: () => void
}

const EmployeeInvitationModal = ({ closeModal }: EmployeeInvitationModalProps) => {
  const [step, setStep] = React.useState<'invite' | 'documents'>('invite')
  const [invitedEmployee, setInvitedEmployee] = React.useState<Employee | undefined>()

  return (
    <Modal onRequestClose={closeModal} className={classNames({ 'h-4/5': step === 'documents' })}>
      {step === 'invite' ? (
        <Invite setInvitedEmployee={setInvitedEmployee} onNext={() => setStep('documents')} />
      ) : null}
      {step === 'documents' && invitedEmployee ? <Documents employee={invitedEmployee} onNext={closeModal} /> : null}
    </Modal>
  )
}

export default EmployeeInvitationModal
