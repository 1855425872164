import React from 'react'
import i18next from 'i18next'

import { DocumentGroupConfig } from 'agency/types/document_groups'
import DocumentGroupConfigsSelect from 'agency/components/DocumentGroupConfigsSelect'
import { Employee } from 'agency/types/employees'
import Text from 'components/Text'
import Icon from 'components/Icon'
import Divider from 'components/Divider'
import Modal from 'agency/components/Modal'
import { capitalizeFirstLetter } from 'utils/string'
import { useAddDocumentGroupRequests } from 'agency/queries/documents'
import CirclesIllustration from 'agency/components/CirclesIllustration'
import { Trade } from 'agency/types/trades'
import Select from 'components/Select'
import toast from 'react-hot-toast'
import { useTrades } from 'agency/queries/trades'

type DocumentsProps = {
  employee: Employee
  onNext: () => void
}

const Documents = ({ employee, onNext }: DocumentsProps) => {
  const { mutate: addDocumentGroupRequest } = useAddDocumentGroupRequests(employee.agency_employee_id, {
    onSuccess: () => onNext(),
  })

  const { data: trades } = useTrades()

  const defaultTrade = trades.find((trade) => trade.isDefault)!

  const [selectedTrade, setSelectedTrade] = React.useState<Trade>(defaultTrade)
  const [selectedDocumentConfigs, setSelectedDocumentConfigs] = React.useState<DocumentGroupConfig[]>(
    defaultTrade.documentGroupsConfigs
  )

  const onTradeChange = (tradeId: string) => {
    const newTrade = trades.find((trade) => trade.id === tradeId)
    if (!newTrade) {
      return
    }

    setSelectedTrade(newTrade)
    setSelectedDocumentConfigs(newTrade.documentGroupsConfigs)
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        if (selectedDocumentConfigs.length === 0) {
          toast.error(i18next.t('agency.home.employee_invitation.document_groups_error'))
          return
        }

        addDocumentGroupRequest({
          selectedDocumentsSlugs: selectedDocumentConfigs.map((documentKind) => documentKind.slug),
          tradeId: selectedTrade.id,
        })
      }}
      className="h-full"
    >
      <div className="flex flex-col justify-between h-full">
        <div className="overflow-y-auto">
          <div className="relative">
            <CirclesIllustration className="absolute -top-6 -left-6" />
            <Icon name="check_circle" size="xlarge" uiStyle="success" className="mb-10 " />
          </div>
          <Text type="title" className="mb-2">
            {i18next.t('agency.home.employee_invitation.invitation_sent', {
              name: [capitalizeFirstLetter(employee.first_name), capitalizeFirstLetter(employee.last_name)].join(' '),
            })}
          </Text>
          <div className="text-textSecondary flex items-center gap-2">
            <Icon name="mail" />
            <Text type="text">{employee.email}</Text>
          </div>
          <Divider className="my-6" />
          <Text type="subtitle" className="mb-2">
            {i18next.t('agency.home.employee_invitation.choose_document_kind')}
          </Text>
          <Text type="subtext" className="mb-4">
            {i18next.t('agency.home.employee_invitation.choose_document_kind_info')}
          </Text>
          {trades.length >= 2 && selectedTrade && (
            <div className="mb-4">
              <Select
                value={selectedTrade.id}
                onValueChange={onTradeChange}
                label={i18next.t('agency.home.employee_invitation.select_trade')}
                placeholder={i18next.t('agency.home.employee_invitation.select_trade')}
                options={trades.map((trade: Trade) => ({ value: trade.id, label: trade.name }))}
              />
            </div>
          )}

          <DocumentGroupConfigsSelect
            selectedDocumentConfigs={selectedDocumentConfigs}
            setSelectedDocumentConfigs={setSelectedDocumentConfigs}
            removableDefault
          />
        </div>
        <Modal.Actions
          cancelable={false}
          submitText={i18next.t('agency.home.employee_invitation.validate_document_list')}
        />
      </div>
    </form>
  )
}

export default Documents
