import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useAgency from 'agency/contexts/AgencyContext'
import { DocumentGroupConfig } from 'agency/types/document_groups'
import { Trade } from 'agency/types/trades'
import httpClient from 'utils/http-client'

const getTradesKey = (agencyId: string | null) => ['trades', agencyId]

type TradeMutationProps = {
  onSuccess: () => void
  onError?: (error: Error) => void
}

export type TradeResponse = {
  id: string
  name: string
  document_groups_slugs: string[]
  document_groups_configs: DocumentGroupConfig[]
  is_default: boolean
  with_employees: boolean
}

export const formatTrade = (trade: TradeResponse): Trade => ({
  id: trade.id,
  name: trade.name,
  documentGroupsSlugs: trade.document_groups_slugs,
  isDefault: trade.is_default,
  documentGroupsConfigs: trade.document_groups_configs,
  withEmployees: trade.with_employees,
})

export const useTrades = () => {
  const { currentAgencyId } = useAgency()

  const { data, ...rest } = useQuery<Trade[]>({
    queryKey: getTradesKey(currentAgencyId),
    queryFn: () =>
      httpClient<TradeResponse[]>(`/agencies/${currentAgencyId}/trades`).then((trades) => trades.map(formatTrade)),
    placeholderData: [],
    staleTime: 1000 * 60 * 10, // 10 minutes
    enabled: !!currentAgencyId,
  })

  return { data: data ?? [], ...rest }
}

export const useSetTradeAsDefault = ({ onSuccess, onError }: TradeMutationProps) => {
  const queryClient = useQueryClient()
  const { currentAgencyId } = useAgency()

  return useMutation({
    mutationFn: (tradeId: string) =>
      httpClient<TradeResponse>(`/agencies/${currentAgencyId}/trades/${tradeId}/set_as_default`, {
        method: 'put',
        body: {},
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getTradesKey(currentAgencyId) })
      onSuccess()
    },
    onError,
  })
}

type AddTradePayload = { name: string; documentGroupsSlugs: string[] }

export const useAddTrade = ({ onError, onSuccess }: TradeMutationProps) => {
  const { currentAgencyId } = useAgency()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ name, documentGroupsSlugs }: AddTradePayload) =>
      httpClient<TradeResponse>(`/agencies/${currentAgencyId}/trades`, {
        method: 'post',
        body: {
          name,
          document_groups_slugs: documentGroupsSlugs,
        },
      }).then(formatTrade),
    onSuccess: (trade: Trade) => {
      queryClient.setQueryData(getTradesKey(currentAgencyId), (trades: Trade[] | undefined) => {
        if (!trades) return [trade]
        return [...trades, trade]
      })
      onSuccess()
    },
    onError,
  })
}

export const useUpdateTrade = ({ onError, onSuccess }: TradeMutationProps) => {
  const queryClient = useQueryClient()
  const { currentAgencyId } = useAgency()

  return useMutation({
    mutationFn: ({
      tradeId,
      name,
      documentGroupsSlugs,
    }: {
      tradeId: string
      name: string
      documentGroupsSlugs: string[]
    }) =>
      httpClient<TradeResponse>(`/agencies/${currentAgencyId}/trades/${tradeId}`, {
        method: 'put',
        body: {
          name,
          document_groups_slugs: documentGroupsSlugs,
        },
      }).then(formatTrade),
    onSuccess: (trade: Trade) => {
      queryClient.setQueryData(getTradesKey(currentAgencyId), (trades: Trade[] | undefined) => {
        if (!trades) return
        return trades.map((t) => (t.id === trade.id ? trade : t))
      })
      onSuccess()
    },
    onError,
  })
}

type UseDeleteTradeProps = {
  onSuccess: () => void
  onError?: (error: Error) => void
}

export const useDeleteTrade = ({ onError, onSuccess }: UseDeleteTradeProps) => {
  const queryClient = useQueryClient()
  const { currentAgencyId } = useAgency()

  return useMutation({
    mutationFn: ({ tradeId, targetTradeId }: { tradeId: string; targetTradeId?: string }) =>
      httpClient<void>(`/agencies/${currentAgencyId}/trades/${tradeId}`, {
        method: 'delete',
        body: {
          target_trade_id: targetTradeId,
        },
      }),
    onSuccess: (_data: void, { tradeId }) => {
      queryClient.setQueryData(getTradesKey(currentAgencyId), (trades: Trade[] | undefined) => {
        if (!trades) return
        return trades.filter((t) => t.id !== tradeId)
      })
      onSuccess()
    },
    onError,
  })
}
