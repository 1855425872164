import React from 'react'
import Text from 'components/Text'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import Pill from 'components/Pill'

type TabProps = {
  label: string
  count?: number
  isActive: boolean
  tab: string
  onClick: (tab: string) => void
}

const Tab = ({ label, count, isActive, tab, onClick }: TabProps) => {
  const showPill = !!count || count === 0
  return (
    <Link
      to={`#${tab}`}
      onClick={() => onClick(tab)}
      className={classNames('border-b-2', {
        'border-white': !isActive,
        'border-primary': isActive,
      })}
    >
      <div className={classNames('flex gap-2 items-center pb-4')}>
        <Text
          type="text"
          className={classNames('font-medium', {
            'text-textSecondary': !isActive,
            'text-primary': isActive,
          })}
        >
          {label}
        </Text>
        {showPill && <Pill count={count} isActive={isActive} />}
      </div>
    </Link>
  )
}

export default Tab
