import SkribeLogo from 'components/SkribeLogo'
import React from 'react'

const SkribeLoadingPage = () => (
  <div className="h-screen w-full bg-white">
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <SkribeLogo size="large" />
    </div>
  </div>
)

export default SkribeLoadingPage
