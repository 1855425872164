import { EmployeeIndex, StatusTagsByCount } from 'agency/types/employees'
import dayjs from 'dayjs'

export type SortDirection = 'asc' | 'desc'
export type SortKey = 'name' | 'document_status' | 'invited_at'
export type Sort = {
  key: SortKey
  direction: SortDirection
}

export type Filter = {
  searchQuery?: string
  agentId?: string
  tradeId?: string
}

const getEmployeeFullName = (employee: EmployeeIndex, reverse?: boolean) =>
  (reverse
    ? `${employee.first_name}${employee.last_name}`
    : `${employee.last_name}${employee.first_name}`
  ).toLowerCase()

const weightForStatusTags = (statusTags: StatusTagsByCount): number => {
  if (statusTags.fraudulent) return 1_000_000
  if (statusTags.complete) return 100_000
  if (statusTags.finalizable) return 10_000
  return -(statusTags.missing ?? 0)
}

export const compareEmployeesByStatusTags = (a: EmployeeIndex, b: EmployeeIndex): number =>
  weightForStatusTags(a.status_tags) - weightForStatusTags(b.status_tags)

export const filterAndSortEmployees = (employees: EmployeeIndex[], sort: Sort, filter: Filter): EmployeeIndex[] => {
  let filteredEmployees = employees.sort(compareEmployeesByStatusTags)

  if (filter.searchQuery) {
    const trimmedSearchQuery = filter.searchQuery.replaceAll(' ', '').toLowerCase()

    filteredEmployees = filteredEmployees.filter(
      (employee) =>
        getEmployeeFullName(employee).includes(trimmedSearchQuery) ||
        getEmployeeFullName(employee, true).includes(trimmedSearchQuery)
    )
  }

  if (filter.agentId && filter.agentId !== 'all') {
    filteredEmployees = filteredEmployees.filter((employee) => employee.agent_id === filter.agentId)
  }

  if (filter.tradeId && filter.tradeId !== 'all') {
    filteredEmployees = filteredEmployees.filter((employee) => employee.trade.id === filter.tradeId)
  }

  if (sort.key === 'name') {
    filteredEmployees = filteredEmployees.sort((a, b) => {
      if (sort.direction === 'asc') {
        return getEmployeeFullName(a).localeCompare(getEmployeeFullName(b))
      }
      return getEmployeeFullName(b).localeCompare(getEmployeeFullName(a))
    })
  }

  if (sort.key === 'invited_at') {
    filteredEmployees = filteredEmployees
      .map((employee) => ({ ...employee, date_invited_at: dayjs(employee.invited_at) }))
      .sort((a, b) => {
        if (sort.direction === 'asc') {
          return a.date_invited_at.diff(b.date_invited_at)
        }
        return b.date_invited_at.diff(a.date_invited_at)
      })
  }

  if (sort.key === 'document_status') {
    filteredEmployees = filteredEmployees.sort((a, b) => {
      if (sort.direction === 'asc') {
        return compareEmployeesByStatusTags(a, b)
      }
      return compareEmployeesByStatusTags(b, a)
    })
  }

  return filteredEmployees
}
