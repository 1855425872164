import React from 'react'
import Modal from 'agency/components/Modal'
import Divider from 'components/Divider'
import Text from 'components/Text'
import i18next from 'i18next'
import Select from 'components/Select'
import { useTrades } from 'agency/queries/trades'
import Input from 'components/Input'
import { useUpdateEmployee } from 'agency/queries/employees'
import toast from 'react-hot-toast'

export type EmployeeUpdatableFields = 'phone_number' | 'trade_id'

type UpdateFormProps = {
  onValueChange: (value: string) => void
  name: string
  value?: string
  error?: string
  placeholder: string
}

const TradeForm = ({ onValueChange, value }: Exclude<UpdateFormProps, 'name' | 'error' | 'placeholder'>) => {
  const { data: trades } = useTrades()

  if (!trades) return null

  return (
    <Select
      options={trades.map((trade) => ({ value: trade.id, label: trade.name }))}
      onValueChange={(v) => onValueChange(v)}
      value={value}
      label={i18next.t('agency.employee_profile.personal_details_section.update_trade')}
    />
  )
}

const TextInputForm = ({ onValueChange, name, value, error, placeholder }: UpdateFormProps) => (
  <Input name={name} onChange={onValueChange} value={value} error={error} placeholder={placeholder} />
)

const FORM_COMPONENT_BY_FIELD = {
  trade_id: TradeForm,
  phone_number: TextInputForm,
}

type UpdateEmployeeInformationModalProps = {
  field: EmployeeUpdatableFields
  onClose: () => void
  value?: string
  agencyEmployeeId: string
}

const UpdateEmployeeInformationModal = ({
  agencyEmployeeId,
  field,
  value: defaultValue,
  onClose,
}: UpdateEmployeeInformationModalProps) => {
  const [value, setValue] = React.useState(defaultValue)
  const [errorMessage, setErrorMessage] = React.useState('')

  const { mutate: updateEmployee } = useUpdateEmployee({
    agencyEmployeeId,
    onSuccess: () => {
      onClose()
      toast.success(i18next.t('agency.employee_profile.personal_details_section.update_success'))
    },
    onError: (error) => {
      if (error.message === 'PHONE_NUMBER') {
        setErrorMessage(i18next.t('agency.home.employee_invitation.phone_number_error'))
      } else {
        toast.error(i18next.t('common.unknown_error'))
      }
    },
  })

  const onSubmit = () => {
    updateEmployee({ [field]: value })
  }

  const FormComponent = FORM_COMPONENT_BY_FIELD[field]

  if (!FormComponent) {
    return null
  }

  const fieldName = i18next.t(`agency.employee_profile.personal_details_section.modal_title.${field}`)

  return (
    <Modal onRequestClose={onClose}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <Text type="title" className="mb-2">
          {fieldName}
        </Text>
        <Divider className="mb-6" />

        <FormComponent
          onValueChange={(v: string) => {
            setValue(v)
          }}
          value={value}
          error={errorMessage}
          name={fieldName}
          placeholder={i18next.t(`agency.employee_profile.personal_details_section.placeholder.${field}`)}
        />
        <Modal.Actions submitText={i18next.t('agency.employee_profile.personal_details_section.update')} />
      </form>
    </Modal>
  )
}

export default UpdateEmployeeInformationModal
