import React from 'react'
import * as RadixCheckbox from '@radix-ui/react-checkbox'
import classNames from 'classnames'
import Icon from './Icon'
import Text from './Text'

type CheckBoxProps = {
  size?: 'small' | 'medium' | 'large'
  name: string
  label: string | React.ReactNode
  checked: boolean
  onChange: (state: boolean) => void
  containerClassName?: string
}

const CHECKBOX_DIMENSIONS = {
  small: 'h-4 w-4',
  medium: 'h-6 w-6',
  large: 'h-8 w-8',
}

const CheckBox = ({ name, label, onChange, checked, containerClassName, size = 'medium' }: CheckBoxProps) => (
  <div className={classNames('flex items-center', containerClassName)}>
    <RadixCheckbox.Root
      className={classNames(
        'flex appearance-none items-center justify-center rounded-1 bg-white border-borderMedium border shrink-0 grow-0',
        CHECKBOX_DIMENSIONS[size]
      )}
      checked={checked}
      onCheckedChange={onChange}
      id={name}
      name={name}
    >
      <RadixCheckbox.Indicator>
        <Icon name="check" size={size} className="text-primary" />
      </RadixCheckbox.Indicator>
    </RadixCheckbox.Root>
    <label htmlFor={name}>
      <Text className="ml-2">{label}</Text>
    </label>
  </div>
)

export default CheckBox
