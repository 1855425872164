import React, { Children, KeyboardEvent, MouseEvent } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

type TableListProps = {
  className?: string
}

const TableList = ({ children, className }: React.PropsWithChildren<TableListProps>) => (
  <table className={classNames('w-full text-left bg-white rounded-md shadow', className)}>
    <tbody>{children}</tbody>
  </table>
)

type RowProps = {
  id?: string
  className?: string
  linkTo?: string
  ariaLabel?: string
}

type GenericRowProps = RowProps & {
  header: boolean
}

const GenericRow = ({
  children,
  className,
  header,
  linkTo,
  ariaLabel,
  id,
}: React.PropsWithChildren<GenericRowProps>) => {
  const navigate = useNavigate()
  const rowLinkProps = linkTo
    ? {
        role: 'link',
        tabIndex: 0,
        'data-href': linkTo,
        onClick: (event: MouseEvent<HTMLTableRowElement>) => {
          event.preventDefault()
          navigate(linkTo)
        },
        onKeyDown: (event: KeyboardEvent<HTMLTableRowElement>) => {
          if (event.key === 'Enter') {
            event.preventDefault()
            navigate(linkTo)
          }
        },
        'aria-label': ariaLabel,
      }
    : { 'aria-label': ariaLabel }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <tr id={id} className={classNames(className, 'border-b border-borderMedium last:border-b-0')} {...rowLinkProps}>
      {Children.map(children, (child) => {
        if (header) {
          return <th className={classNames('px-6 py-4')}>{child}</th>
        }
        return <td className={classNames('px-6 py-4')}>{child}</td>
      })}
    </tr>
  )
}

const Header = ({ children, className, linkTo, ariaLabel, id }: React.PropsWithChildren<RowProps>) => (
  <GenericRow id={id} header className={className} linkTo={linkTo} ariaLabel={ariaLabel}>
    {children}
  </GenericRow>
)

const Row = ({ children, className, linkTo, ariaLabel, id }: React.PropsWithChildren<RowProps>) => (
  <GenericRow id={id} header={false} className={className} linkTo={linkTo} ariaLabel={ariaLabel}>
    {children}
  </GenericRow>
)

TableList.Header = Header
TableList.Row = Row
export default TableList
