import React from 'react'
import TableList from 'components/TableList'
import Text from 'components/Text'
import i18next from 'i18next'

const TradeHeader = () => (
  <TableList.Header>
    <Text uiStyle="bold" type="text">
      {i18next.t('agency.settings.trades.name')}
    </Text>
    <Text uiStyle="bold" type="text">
      {i18next.t('agency.settings.trades.default')}
    </Text>
    <Text uiStyle="bold" type="text">
      {i18next.t('agency.settings.trades.document_groups_slugs')}
    </Text>
    <Text uiStyle="bold" type="text">
      {i18next.t('agency.settings.trades.actions')}
    </Text>
  </TableList.Header>
)

export default TradeHeader
