import { useQueryClient } from '@tanstack/react-query'
import { DocumentGroup } from 'agency/types/document_groups'
import { EmployeeShow, EmployeeIndex } from 'agency/types/employees'
import useAgency from 'agency/contexts/AgencyContext'
import { getEmployeeKey, getEmployeesKey } from './employees'

export const useUpdateDocumentGroupsForEmployee = (agencyEmployeeId?: string) => {
  const { currentAgencyId } = useAgency()
  const queryClient = useQueryClient()

  return (documentGroups: DocumentGroup[]) => {
    queryClient.setQueryData(
      getEmployeeKey(agencyEmployeeId),
      (employee: EmployeeShow): EmployeeShow => ({ ...employee, documentGroups })
    )
    queryClient.setQueryData(getEmployeesKey(currentAgencyId), (employees: EmployeeIndex[]): EmployeeIndex[] => {
      if (!employees) {
        return []
      }
      const impactedEmployee = employees.find((employee) => employee.agency_employee_id === agencyEmployeeId)

      if (!impactedEmployee) {
        return employees
      }

      return [
        ...employees.filter((employee) => employee.agency_employee_id !== agencyEmployeeId),
        {
          ...impactedEmployee,
          document_groups_slugs: documentGroups.map((documentGroup) => documentGroup.kind_slug),
        },
      ]
    })
  }
}
