import React from 'react'
import Modal from 'react-modal'
import { createRoot } from 'react-dom/client'
import { createRoutesFromElements, Route, RouterProvider, createBrowserRouter } from 'react-router-dom'
import i18next from 'i18next'
import { QueryClientProvider } from '@tanstack/react-query'

import PageWrapper from 'components/PageWrapper'
import RouteWatcher from 'components/RouteWatcher'
import { initAppsignal } from 'utils/appsignal'
import { initAmplitude } from 'utils/amplitude'
import { initDayjs } from 'utils/dayjs'
import { getDefaultQueryClient } from 'utils/react-query'
import ErrorPage from 'components/ErrorPage'
import Toaster from 'components/Toaster'
import useSetupHubSpotChat from './hooks/useSetupHubSpotChat'
import Login from './pages/Login'
import DefaultRouteRedirection from './routing/DefaultRouteRedirection'
import Skeleton from './routing/Skeleton'
import PublicRoutes from './routing/PublicRoutes'
import LoggedInRoutes from './routing/LoggedInRoutes'
import AgencyEmployeeIndex from './pages/agency_employees/AgencyEmployeeIndex'
import AgencyEmployeeShow from './pages/agency_employees/AgencyEmployeeShow'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import { AgencyProvider } from './contexts/AgencyContext'
import CurrentAgencyRedirection from './pages/CurrentAgencyRedirection'
import AgencySettingsShow from './pages/AgencySettingsShow'

Modal.setAppElement('#root')

const queryClient = getDefaultQueryClient()

initAppsignal('agency')
initAmplitude('agency')

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RouteWatcher />} errorElement={<ErrorPage />}>
      <Route
        element={
          <AgencyProvider>
            <LoggedInRoutes />
          </AgencyProvider>
        }
      >
        <Route element={<Skeleton />}>
          <Route element={<CurrentAgencyRedirection />}>
            {/* This route is used to wait while we receive the agent information (include the agencies) to properly redirect */}
            <Route
              path="/agency_employees"
              element={<PageWrapper titleKey="agency.home.employee_list_title" component={() => null} />}
            />
            <Route
              path="/agencies/:agencyId/agency_employees"
              element={<PageWrapper titleKey="agency.home.employee_list_title" component={AgencyEmployeeIndex} />}
            />
            <Route
              path="/agencies/:agencyId/agency_employees/:agencyEmployeeId"
              element={<PageWrapper titleKey="agency.employee_profile.title" component={AgencyEmployeeShow} />}
            />
            <Route
              path="/agencies/:agencyId/settings"
              element={<PageWrapper titleKey="agency.settings.title" component={AgencySettingsShow} />}
            />
          </Route>
        </Route>
      </Route>

      <Route element={<PublicRoutes />}>
        <Route path="/login" element={<PageWrapper titleKey="agency.sign_in.title" component={Login} />} />
        <Route
          path="/agent/password/new"
          element={<PageWrapper titleKey="agency.forgot_password.title" component={ForgotPassword} />}
        />
        <Route
          path="/agent/password/edit"
          element={<PageWrapper titleKey="agency.reset_password.title" component={ResetPassword} />}
        />
      </Route>

      <Route path="*" element={<DefaultRouteRedirection />} />
    </Route>
  )
)

const App = () => {
  useSetupHubSpotChat()
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster />
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('root')
  if (container) {
    i18next.init({
      lng: 'fr',
      resources: window.translation_keys,
    })
    initDayjs()

    const root = createRoot(container)
    root.render(<App />)
  }
})
