import React from 'react'
import Text from 'components/Text'
import Divider from 'components/Divider'
import ClickableDiv from 'components/ClickableDiv'
import Icon from 'components/Icon'
import IconButton from 'components/IconButton'
import i18next from 'i18next'
import toast from 'react-hot-toast'
import dayjs from 'dayjs'
import { EmployeeShow } from 'agency/types/employees'
import { useTrades } from 'agency/queries/trades'
import UpdateEmployeeInformationModal, { EmployeeUpdatableFields } from './UpdateEmployeeInformationModal'

type EmployeeInformationRowProps = {
  label: string
  text?: string
  hideDivider?: boolean
  onUpdateClick?: () => void
}

const EmployeeInformationRow = ({ label, text, hideDivider, onUpdateClick }: EmployeeInformationRowProps) => {
  const [showCopyButton, setShowCopyButton] = React.useState(false)

  const onCopyClick = () => {
    if (!text) {
      return
    }
    // Clipboard API is only available in secure (HTTPS) contexts
    if (window.isSecureContext) {
      navigator.clipboard.writeText(text)
    }
    toast.success(i18next.t('agency.employee_profile.personal_details_section.copy_success'))
  }

  const updateLabel = i18next.t('agency.employee_profile.personal_details_section.update_info', { label })

  return (
    <div onMouseEnter={() => text && setShowCopyButton(true)} onMouseLeave={() => setShowCopyButton(false)}>
      <div className="flex flex-row gap-8 items-center py-4 px-4">
        <Text type="subtext" className="min-w-64">{`${label}`}</Text>
        <ClickableDiv
          label={i18next.t('agency.employee_profile.personal_details_section.copy_info', { label })}
          onClick={onCopyClick}
          className="flex flex-row grow gap-8 items-center"
        >
          <Text>{text ?? '-'}</Text>
          {showCopyButton && <Icon size="medium" name="copy" />}
        </ClickableDiv>
        {onUpdateClick && <IconButton label={updateLabel} onClick={onUpdateClick} name="edit" />}
      </div>
      {!hideDivider && <Divider />}
    </div>
  )
}

type EmployeeInformationProps = {
  employee: EmployeeShow
}

const EmployeeInformation = ({ employee }: EmployeeInformationProps) => {
  const { data: trades } = useTrades()

  const [updateModalProps, setUpdateModalProps] = React.useState<{
    show: boolean
    field?: EmployeeUpdatableFields
    value?: string
  }>({
    show: false,
  })

  const onCloseModal = () => {
    setUpdateModalProps({ show: false })
  }

  const onUpdateClick = (field: EmployeeUpdatableFields, value?: string) => () => {
    setUpdateModalProps({ show: true, field, value })
  }

  return (
    <div id="employee_profile_information" className="w-full text-left bg-white rounded-md shadow">
      <Text type="subtitle" className="px-4 py-3">
        {i18next.t('agency.employee_profile.personal_details_section.title')}
      </Text>
      <Divider />
      <div>
        <EmployeeInformationRow
          label={i18next.t('agency.employee_profile.personal_details_section.last_name')}
          text={employee.last_name}
        />
        <EmployeeInformationRow
          label={i18next.t('agency.employee_profile.personal_details_section.first_name')}
          text={employee.first_name}
        />
        <EmployeeInformationRow
          label={i18next.t('agency.employee_profile.personal_details_section.email')}
          text={employee.email}
        />
        <EmployeeInformationRow
          label={i18next.t('agency.employee_profile.personal_details_section.phone_number')}
          text={employee.phone_number}
          onUpdateClick={onUpdateClick('phone_number', employee.phone_number)}
        />
        {trades.length >= 2 && (
          <EmployeeInformationRow
            label={i18next.t('agency.employee_profile.personal_details_section.trade')}
            text={employee.trade.name}
            onUpdateClick={onUpdateClick('trade_id', employee.trade.id)}
          />
        )}
        <EmployeeInformationRow
          label={i18next.t('agency.employee_profile.personal_details_section.birthdate')}
          text={employee.birthdate ? dayjs(employee.birthdate).format('DD/MM/YYYY') : undefined}
        />
        <EmployeeInformationRow
          label={i18next.t('agency.employee_profile.personal_details_section.social_security_number')}
          text={employee.socialSecuryNumber}
        />
        <EmployeeInformationRow
          label={i18next.t('agency.employee_profile.personal_details_section.iban')}
          text={employee.iban}
        />
        <EmployeeInformationRow
          label={i18next.t('agency.employee_profile.personal_details_section.bic')}
          text={employee.bic}
          hideDivider
        />
      </div>

      {updateModalProps.show && (
        <UpdateEmployeeInformationModal
          onClose={onCloseModal}
          agencyEmployeeId={employee.agency_employee_id}
          field={updateModalProps.field!}
          value={updateModalProps.value}
        />
      )}
    </div>
  )
}

export default EmployeeInformation
