import React, { useState } from 'react'
import Modal from 'react-modal'
import i18next from 'i18next'

import Icon from 'components/Icon'
import IconButton from 'components/IconButton'
import Divider from 'components/Divider'
import Text from 'components/Text'

import { useDocumentUpload } from 'agency/queries/documents'
import { DocumentGroup } from 'agency/types/document_groups'
import Button from 'components/Button'
import UploadField from 'agency/components/UploadField'
import DocumentViewer from 'components/DocumentViewer'
import { createFileObject } from 'utils/documents'
import Select, { type Item } from 'components/Select'
import toast from 'react-hot-toast'
import { UPLOAD_FILE_SIZE_ERROR } from 'utils/post-file'
import { sendError } from 'utils/appsignal'
import { sortBy } from 'utils/sort'

type TDocumentUploadProps = {
  documentGroups: DocumentGroup[]
  agencyEmployeeId: string
  closePreview: () => void
}

const formatOptions = (documentGroups: DocumentGroup[]): Item[] =>
  sortBy<Item>(
    documentGroups
      .map((documentGroup) =>
        documentGroup.document_options.map((documentSlug) => ({
          label: i18next.t(`document_group_config.${documentSlug}`),
          value: `${documentGroup.kind_slug}-${documentSlug}`,
        }))
      )
      .flat(),
    'label'
  )

const DocumentUpload = ({ documentGroups, agencyEmployeeId, closePreview }: TDocumentUploadProps) => {
  const [file, setFile] = useState<File | null>(null)
  const options = formatOptions(documentGroups)
  const [selectedOption, setSelectedOption] = useState<Item>(options[0])

  const { mutate: uploadDocument, isPending } = useDocumentUpload({
    onSuccess: () => {
      toast.success(i18next.t('agency.employee_profile.document_section.import_document_confirm'))
      closePreview()
    },
    onError: (error: Error) => {
      if (error.message === UPLOAD_FILE_SIZE_ERROR) {
        toast.error(i18next.t('common.document_upload.max_size_error'))
      } else {
        sendError(error)
        toast.error(i18next.t('common.document_upload.upload_error'))
      }
      setFile(null)
    },
  })

  const onConfirm = () => {
    if (!file) return

    const [documentGroupSlug, documentSlug] = selectedOption.value.split('-')

    uploadDocument({
      agencyEmployeeId,
      documentGroupSlug,
      documentSlug,
      file,
    })
  }

  return (
    <Modal
      overlayClassName="items-end fixed inset-0 document-upload-modal"
      className="h-screen w-1/2 bg-white absolute top-0 right-0 border-l shadow border-l-borderMedium"
      onRequestClose={closePreview}
      isOpen
    >
      <div className="flex flex-col h-screen">
        <div className="p-6 flex items-center justify-between">
          <Icon name="cloud_upload" className="mr-2" />
          <Text className="grow" type="subtitle">
            {i18next.t('agency.employee_profile.document_section.document_list.import_for', {
              label: selectedOption.label,
            })}
          </Text>
          <div className="flex gap-2 items-center">
            <IconButton name="close" label={i18next.t('common.close')} onClick={closePreview} />
          </div>
        </div>
        <Divider />
        <div className="bg-backgroundPrimary grow mb-16 overflow-y-scroll">
          <Text className="text-center pt-4 px-4">
            {i18next.t('agency.employee_profile.document_section.import_description')}
          </Text>
          {options.length > 1 && (
            <div className="w-full px-8 pt-8">
              <Select
                onValueChange={(newValue: string) => {
                  setSelectedOption(options.find((option) => option.value === newValue) ?? selectedOption)
                }}
                placeholder={i18next.t(
                  'agency.employee_profile.document_section.document_list.select_document_placeholder'
                )}
                defaultValue={options[0].value}
                options={options}
                className="grow"
                label={i18next.t('agency.employee_profile.document_section.document_list.select_document_label')}
              />
            </div>
          )}
          {file ? (
            <DocumentViewer
              document={createFileObject(file, selectedOption.label)}
              className="w-full h-4/5 mt-5"
              mobileDisplayDisabled
            />
          ) : (
            <UploadField onChange={setFile} />
          )}
        </div>

        <div className="absolute bottom-0 left-0 right-0 bg-backgroundPrimary ">
          <div className="w-full flex p-4">
            <Button
              className="bg-primary text-white grow"
              name="import"
              disabled={!file}
              loading={isPending}
              onClick={onConfirm}
            >
              {i18next.t('common.import')}
            </Button>
            {file && (
              <Button name="close" uiStyle="delete" onClick={() => setFile(null)} className="ml-2">
                {i18next.t('common.cancel')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DocumentUpload
