import React from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import classNames from 'classnames'

type DropdownItemProps = { children: React.ReactNode; onClick?: () => void }

const DropdownItem = ({ children, onClick }: DropdownItemProps) => (
  <DropdownMenu.Item
    className="flex items-center data-[highlighted]:bg-backgroundPrimary data-[highlighted]:cursor-pointer px-4 py-2 outline-none w-full"
    onSelect={onClick}
    asChild
  >
    {children}
  </DropdownMenu.Item>
)

type DropdownLabelProps = { children: React.ReactNode }

const DropdownLabel = ({ children }: DropdownLabelProps) => (
  <DropdownMenu.Label className="p-4 w-full">{children}</DropdownMenu.Label>
)

const DropdownSeparator = () => <DropdownMenu.Separator className="border border-borderMedium" />

type DropdownProps = { children: React.ReactNode; trigger: React.ReactNode; label: string; className?: string }

const Dropdown = ({ children, trigger, label, className }: DropdownProps) => (
  <DropdownMenu.Root>
    <DropdownMenu.Trigger aria-label={label}>{trigger}</DropdownMenu.Trigger>
    <DropdownMenu.Content
      className={classNames('border border-borderMedium bg-white rounded-lg drop-shadow', className)}
      align="end"
    >
      {children}
    </DropdownMenu.Content>
  </DropdownMenu.Root>
)

Dropdown.Item = DropdownItem
Dropdown.Label = DropdownLabel
Dropdown.Separator = DropdownSeparator
export default Dropdown
