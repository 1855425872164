import classNames from 'classnames'
import React from 'react'
import { formatNumberForPill } from 'utils/string'

type PillProps = {
  count: number
  isActive: boolean
}

const Pill = ({ count, isActive }: PillProps) => (
  <div
    className={classNames('rounded-[6rem] inline-flex justify-center px-3 py-[0.5] shadow', {
      'text-white bg-primary border border-primary': isActive,
      'text-textSecondary bg-white border border-borderMedium': !isActive,
    })}
  >
    {formatNumberForPill(count)}
  </div>
)

export default Pill
