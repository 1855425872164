import React, { useEffect } from 'react'
import { useRouteError } from 'react-router-dom'
import { sendError } from 'utils/appsignal'
import ErrorPlaceholder from './ErrorPlaceholder'

const ErrorPage = () => {
  const error = useRouteError()

  useEffect(() => {
    if (!error) return
    sendError(error)
  }, [error])

  return (
    <div className="h-screen w-screen">
      <ErrorPlaceholder />
    </div>
  )
}

export default ErrorPage
