import useAgency from 'agency/contexts/AgencyContext'
import { getAgencyRoute } from 'agency/utils/agency-routes'
import IconButton from 'components/IconButton'
import i18next from 'i18next'
import React from 'react'
import { useNavigate } from 'react-router-dom'

type HeaderProps = {
  id?: string
  tabs?: React.ReactNode[]
}

const Header = ({ id, children, tabs }: React.PropsWithChildren<HeaderProps>) => {
  const navigate = useNavigate()
  const { currentAgencyId } = useAgency()

  const onClick = () => {
    if (!currentAgencyId) return

    navigate(getAgencyRoute(currentAgencyId, '/agency_employees'))
  }

  return (
    <div id={id} className="bg-white w-screen">
      <div className="flex items-center gap-4 py-5 px-14">
        <IconButton name="arrow_back" label={i18next.t('common.go_back')} onClick={onClick} />
        {children}
      </div>
      {tabs && <div className="flex gap-8 px-14">{tabs}</div>}
    </div>
  )
}

const Page = ({ children }: React.PropsWithChildren) => <div className="flex flex-col grow w-screen">{children}</div>

Page.Header = Header

export default Page
