import React from 'react'
import Text from 'components/Text'
import { EmployeeIndex } from 'agency/types/employees'
import { capitalizeFirstLetter } from 'utils/string'
import EmployeeRow from './EmployeeRow'
import EmployeeStatus from './EmployeeStatus'
import InvitedEmployeeActionButton from '../InvitedEmployeeActionButton'

type InvitedEmployeeRowProps = {
  employee: EmployeeIndex
  showTrade: boolean
}

const InvitedEmployeeRow = ({ employee, showTrade }: InvitedEmployeeRowProps) => (
  <EmployeeRow key={employee.agency_employee_id} employee={employee}>
    <Text className="employee-name">
      {employee.last_name.toUpperCase()} {capitalizeFirstLetter(employee.first_name)}
    </Text>
    <EmployeeStatus employee={employee} />
    <Text>{employee.email}</Text>
    <Text>{employee.phone_number ? employee.phone_number : '-'}</Text>
    {showTrade && <Text>{employee.trade.name}</Text>}
    <InvitedEmployeeActionButton employee={employee} />
  </EmployeeRow>
)

export default InvitedEmployeeRow
